<template>
    <div class="d-flex ">
        <!-- side bar -->
        <sidebar></sidebar>
        <div class="col-md col-12">
            <div class="d-flex flex-row justify-content-between border">
                <div class="p-2 d-flex col-md align-items-center">
                    <button class="btn p-0 ms-2 me-4 d-md-none d-block" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"><i class="mdi mdi-menu h3 text-black-50"></i></button>
                    <!-- <button class="btn btn-outline-primary"><i class="mdi mdi-plus"></i>Tambah uhuy</button> -->
                </div>
                <div class="p-2 me-2   d-flex justify-content-end col-md align-items-center">
                    <div class="dropdown">
                        <a class="nav-link d-flex align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <p class="mb-0 text-black-50 fw-bold">Welcome, {{username}}</p>
                            <img src="assets/images/admin.png" class="rounded-circle img-fluids" style="width: 32px;">
                        </a>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item" v-on:click="loadProfile()"><span class="mdi mdi-cog text-black-50"> Setting</span></a></li>
                          <li><a class="dropdown-item" v-on:click="logoutSystem()"><span class="mdi mdi-logout-variant text-black-50"> Logout</span></a></li>
                        </ul>
                      </div>
                </div>
            </div>
            <div class="p-0">
                <keep-alive>
                <router-view class="pt-1 mt-0 scrl-y" style="overflow: auto;" ></router-view>
                </keep-alive>
            </div>
        </div>
        <!-- sidebar -->
                  <msidebar></msidebar>
    </div>
</template>
<script>
/* eslint-disable */
import Sidebar from '@/components/Sidebar.vue';
import Msidebar from '@/components/MSidebar.vue';

export default {
    name: "Dashboard",
    components: {
      Sidebar,
      Msidebar
    },
    data() {
        return {
            sideHeight: 0,
            username: "",
            panel: false,
            appshow: false,
            menus: [],
            // pr_code: localStorage.program,
            menuinduk: localStorage.menuinduk,
            level: localStorage.tsaleslevel,
            txtcari: "",
            selectedRow: '',
            namaapp: this.appName
        };
    },
    methods: {
        loadMenu() {
            let self = this;
            // let obj = await self.deAsync("/tproject/admin/getmenu", {
            //     pr_code: self.pr_code,
            // });
            self.username = localStorage.username;
            // self.menus = obj.data.rec;
        },
        openMenu() {
            var mySidebar = document.getElementById("mySidebar");
            var overlayBg = document.getElementById("myOverlay");

            if (
                mySidebar.style.display === "block!important" ||
                mySidebar.style.display === "block"
            ) {
                mySidebar.style.display = "none";
                overlayBg.style.display = "none";
            } else {
                mySidebar.style.display = "block";
                overlayBg.style.display = "block";
            }
        },
        closeMenu() {
            let mySidebar = document.getElementById("mySidebar");
            let overlayBg = document.getElementById("myOverlay");
            mySidebar.style.display = "none";
            // overlayBg.style.display = "none";
        },
        getIcon(mn) {
            if (mn.mn_type == 'P') {
                if (mn.mn_icon != "" && mn.mn_icon != null && mn.mn_icon != "-") {
                    return mn.mn_icon;
                } else {
                    return "fa fa-book";
                }
            } else {
                return '';
            }
        },
        logoutSystem() {
            var self = this;
            swal(
                {
                    title: "Keluar Program",
                    text: "Yakin Ingin Keluar Program ..!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Keluar",
                    cancelButtonText: "Tidak",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                },
                function (isConfirm) {
                    if (isConfirm) {
                        localStorage.clear();
                        self.$router.push("/");
                        location.reload();
                    }
                }
            );
        },
        loadProfile() {
            this.$router.push("/profile");
        }
    },
    watch: {
        $route: function () {
            this.menuinduk = this.$route.path;
            this.closeMenu();
        },
    },
    mounted() {
        this.sideHeight = window.innerHeight;
        this.menuinduk = this.$route.path;
        // this.namaapp = this.appName;
        this.loadMenu();
    },
};
</script>

