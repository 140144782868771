<template>
    <div class="d-md-none d-block">
      <div class="offcanvas offcanvas-start w-50" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
        <div class="offcanvas-header">
          <div class="text-center p-3 mb-3">
            <a :href="defaultDashboardUrl" class="nav-link"><img src="assets/images/spf-logo.png" alt="" class="img-fluid w-75"></a>
          </div>
        </div>
        <div class="offcanvas-body">
          <!-- sidebar Menu -->
          <div class="w3-bar-block scrl-y" :style="'max-height:' + (sideHeight - 50) + 'px;'">
            <div class="text-black-50 px-3">
              <p class="h7">MAIN</p>
              <div class="accordion accordion-flush border-0" id="accordionFlushExample">
                <div v-for="menu in filteredMenus" :key="menu.id" class="accordion-item text-black-50">
                  <h2 class="accordion-header">
                    <a class="accordion-button collapsed nav-link text-black-50"
                       :class="{ active: menu.isActive }"
                       :type="menu.submenus ? 'button' : null"
                       :data-bs-toggle="menu.submenus ? 'collapse' : null"
                       :data-bs-target="menu.submenus ? '#' + menu.id : null"
                       aria-expanded="false"
                       :aria-controls="menu.submenus ? menu.id : null">
                      <span :class="menu.iconClass" class="me-3"></span> {{ menu.title }}
                    </a>
                  </h2>
                  <div v-if="menu.submenus" :id="menu.id" class="accordion-collapse collapse" :aria-labelledby="menu.id" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <a v-for="submenu in menu.submenus" :key="submenu.id"
                        :href="submenu.url || defaultDashboardUrl"
                        class="nav-item nav-link d-flex justify-content-between mb-3"
                        :class="{ active: submenu.isActive } ">
                        <span :class="submenu.iconClass">{{ submenu.title }}</span>
                        <span class="mdi mdi-chevron-right"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    data() {
      return {
        defaultDashboardUrl: '',
        sideHeight: window.innerHeight,
        menus: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          iconClass: 'mdi mdi-view-dashboard-outline',
          isActive: false,
          submenus: [
            { id: 'home', title: 'Home', iconClass: 'mdi mdi-circle-medium', url: this.defaultDashboardUrl },
            { id: 'd-cust', title: 'Dashboard Customer', iconClass: 'mdi mdi-circle-medium', url: '#/dashboard/customer', level: ['1'], dept: ['6','1'] },
            { id: 'd-supp', title: 'Dashboard Supplier', iconClass: 'mdi mdi-circle-medium', url: '#/dashboard/suplier', level: ['1'], dept: ['6','1'] },
          ]
        },
        {
          id: 'gudang',
          title: 'Gudang',
          iconClass: 'mdi mdi-chart-areaspline',
          isActive: false,
          submenus: [
            { id: 'i-gudang', title: 'Index Gudang', iconClass: 'mdi mdi-circle-medium', url: '#/i-gudang', level: ['2','1'], dept: ['4','1'] },
            { id: 'm-gudang', title: 'Mutasi Gudang', iconClass: 'mdi mdi-circle-medium', url: '#/m-gudang', level: ['2','1'], dept: ['4','1'] }
            // Add more submenus here
          ]
        },
        {
          id: 'kendaraan',
          title: 'Kendaraan',
          iconClass: 'mdi mdi-truck',
          isActive: false,
          submenus: [
            { id: 'perbaikan', title: 'Sistem Perbaikan', iconClass: 'mdi mdi-circle-medium', url: '#/perbaikan', level: ['2','1'], dept: ['1','3'] },
            { id: 'kendaraan', title: 'Data Kendaraan', iconClass: 'mdi mdi-circle-medium', url: '#/kendaraan', level: ['2','1'], dept: ['1','3'] },
            { id: 'stafel', title: 'Stafel', iconClass: 'mdi mdi-circle-medium', url: '#/stafel', level: ['2','1'], dept: ['1','3'] },
            // { id: 'k-inventaris', title: 'Inventaris', iconClass: 'mdi mdi-circle-medium', url: '#/k-inventaris', level: ['2','1'], dept: ['1','3'] }
            // Add more submenus here
          ]
        },
        {
          id: 'inventris',
          title: 'Inventaris',
          iconClass: 'mdi mdi-warehouse',
          isActive: false,
          submenus: [
            { id: 'inventori', title: 'Inventaris', iconClass: 'mdi mdi-circle-medium', url: '#/inventori', level: ['2','1'],dept: ['1']},
            { id: 'lokasi-inventaris', title: 'Master Lokasi', iconClass: 'mdi mdi-circle-medium', url: '#/lokasi-inventaris', level: ['2','1'], dept: ['1'] },
            { id: 'supp-inventaris', title: 'Master Supplier', iconClass: 'mdi mdi-circle-medium', url: '#/supp-inventaris', level: ['2','1'], dept: ['1'] },
            { id: 'po-inventaris', title: 'Order Inventaris', iconClass: 'mdi mdi-circle-medium', url: '#/po-inventaris', level: ['1'], dept: ['1']},

            // Add more submenus here
          ]
        },
        {
          id: 'pembelian',
          title: 'Pembelian',
          iconClass: 'mdi mdi-currency-usd',
          isActive: false,
          submenus: [
            { id: 'D-Supplier', title: 'Data Supplier', iconClass: 'mdi mdi-circle-medium', url: '#/d-supplier', level: ['1','2'], dept: ['1','2'] },
            { id: 'cek-stok', title: 'Cek Stok', iconClass: 'mdi mdi-circle-medium', url: '#/cek-stok', level: ['1','2'], dept: ['1','2'] }

            // Add more submenus here
          ]
        },
        {
          id: 'marketing',
          title: 'Marketing',
          iconClass: 'mdi mdi-chart-donut-variant',
          isActive: false,
          submenus: [
            { id: 'pengajuan', title: 'Pengajuan Penjualan', iconClass: 'mdi mdi-circle-medium', url: '#/pengajuan', level: ['1','2'], dept: ['1','5'] },
            { id: 'approved', title: 'Approved Pengajuan', iconClass: 'mdi mdi-circle-medium', url: '#/marketing/approved', level: ['1'], dept: ['1','5'] },
            { id: 'cek-stok', title: 'Cek Stok', iconClass: 'mdi mdi-circle-medium', url: '#/cek-stok', level: ['1','2'], dept: ['1','5'] },
            { id: 'customer', title: 'Data Customer', iconClass: 'mdi mdi-circle-medium', url: '#/customer', level: ['1','2'], dept: ['1','5'] },
            { id: 'stafel', title: 'Stafel', iconClass: 'mdi mdi-circle-medium', url: '#/stafel', level: ['2','1'], dept: ['1','5'] },
            { id: 'outstanding', title: 'Data Sales Order', iconClass: 'mdi mdi-circle-medium', url: '#/marketing/outstanding', level: ['2','1'], dept: ['1','5'] }

            // Add more submenus here
          ]
        },
        {
          id: 'Master',
          title: 'Master',
          iconClass: 'mdi mdi-shield-crown',
          isActive: false,
          submenus: [
            // { id: 'master-approved', title: 'Approved', iconClass: 'mdi mdi-circle-medium', url: '#/master/approved', level: ['1'], dept: ['1','6'] },
            { id: 'customer', title: 'Data Customer', iconClass: 'mdi mdi-circle-medium', url: '#/customer', level: ['1','2'], dept: ['1','5','6'] }
          ]
        },
        {
          id: 'Sales',
          title: 'Sales',
          iconClass: 'mdi mdi-shield-crown',
          isActive: false,
          submenus: [
            { id: 'target-sales', title: 'Target Sales', iconClass: 'mdi mdi-circle-medium', url: '#/sales/target', level: ['1'], dept: ['6','1'] },
            { id: 'penjualan-sales', title: 'Penjualan Sales', iconClass: 'mdi mdi-circle-medium', url: '#/sales/penjualan', level: ['1'], dept: ['6','1'] }
          ]
        },
      ]
    };
    },
    computed: {
    filteredMenus() {
        // Mengambil level pengguna dari localStorage atau dari sumber data lain yang sesuai
        const userLevel = localStorage.getItem('level'); // Ubah 'userLevel' dengan kunci yang sesuai
        const userDept = localStorage.getItem('dept'); // Tambah baris ini untuk mendapatkan departemen pengguna

        // Menyaring menu dan submenunya berdasarkan level pengguna dan departemen
        return this.menus.filter(menu => {
          if (!menu.submenus) {
            // Jika menu tidak memiliki submenus, tampilkan menu tersebut
            return true;
          } else {
            // Jika menu memiliki submenus, saring submenus berdasarkan level pengguna dan departemen
            menu.submenus = menu.submenus.filter(submenu => {
              const isLevelValid = !submenu.level || submenu.level.includes(userLevel);
              const isDeptValid = !submenu.dept || submenu.dept.includes(userDept); // Tambah ini untuk saring berdasarkan departemen
              return isLevelValid && isDeptValid;
            });

            // Tampilkan menu hanya jika memiliki submenus yang tersisa setelah penyaringan
            return menu.submenus.length > 0;
          }
        });
      },
      computedDefaultDashboardUrl() {
        const userDepartment = localStorage.getItem('dept');
        const userLevel = localStorage.getItem('level');

        switch (userDepartment) {
          case '1':
            return '#/dashboard-admin';
          case '2':
            return '#/dashboard-purchase';
          case '3':
            return '#/dashboard-kendaraan';
          case '4':
            return '#/dashboard-gudang';
          case '5':
            return '#/dashboard-marketing';
          case '6':
            if (userLevel === '1') {
              return '#/dashboard-admin';
            } else if (userLevel === '2') {
              return '#/dashboard-semarang';
            } else if (userLevel === '3') {
              return '#/dashboard-malang';
            } else {
              return '#/home';
            }
          default:
            return '#/home';
      }
    },
  },
    watch: {
    computedDefaultDashboardUrl: {
      handler(newUrl) {
        this.defaultDashboardUrl = newUrl;
      },
      immediate: true, // This ensures the handler is called immediately when the component is created
    },
  },
};
  </script>

  <style>
  /* Add custom styles here */
  </style>
